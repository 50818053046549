import Vue from "vue";
import VueMeta from "vue-meta";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import utils from "./utils";
import { addStyles } from "./registerQuill";

Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.mixin(utils);

addStyles();

new Vue({
  mixins: [utils],
  router,
  store,
  render: (h) => h(App),
  metaInfo() {
    return {
      title: "Novum Initium",
      meta: [
        {
          name: "description",
          content: `Welkom op onze website over onze Smooth Collie kennel Novum Initium

Wij zijn begonnen met fokken uit liefde voor het mooie ras
Naast het fokken en streven naar de ras standaard, staat hierbij karakter en gezondheid voorop!
Al onze honden worden of zijn getest op DNA, CEA/PRA, MDR1, DM en HD.

Wij fokken volgens de regels van de, door de Raad van Beheer erkende rasvereniging de Smooth Collie Friends. 

Op onze website kunt u alles vinden over onze collies en over ons.`,
        },
        {
          property: "og:title",
          content: "Novum Initium",
        },
        { property: "og:site_name", content: "Novum Initium" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
}).$mount("#app");
