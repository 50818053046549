<template>
  <aside class="slide-show">
    <vueper-slides
      class="no-shadow"
      :arrows-outside="!innerArrows"
      bullets-outside
      transition-speed="250"
      fixedHeight="400px"
      v-if="imageGroup"
    >
      <vueper-slide
        v-for="(image, i) in imageGroup.images"
        :key="i"
        :content="`<img src='${'/api/image?path=' + image.link}' alt='${
          image.name
        } ${image.description}'>`"
      />
    </vueper-slides>
    <vueper-slides
      class="no-shadow"
      :arrows-outside="!innerArrows"
      bullets-outside
      transition-speed="250"
      fixedHeight="400px"
      v-else
    >
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :title="slide.title"
        :content="slide.content"
      />
    </vueper-slides>
  </aside>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "Slides",
  props: {
    innerArrows: {
      type: Boolean,
      default: false,
    },
    slides: {
      type: Array,
      default: () => [],
    },
    imageGroup: {
      type: Object,
      default: null,
    },
  },
  components: { VueperSlides, VueperSlide },
};
</script>

<style scoped>
.slide-show >>> .vueperslide__content {
  max-height: 100%;
  max-width: 100%;
}

.slide-show >>> .vueperslide__content img {
  max-height: 100%;
  max-width: 100%;
}

.slide-show >>> .vueperslides__arrows,
.slide-show >>> .vueperslides__bullets {
  color: var(--primary) !important;
}
</style>
