<template>
  <div class="home">
    <div class="wrapper">
      <section class="about">
        <div class="info">
          <main v-html="content.content" />

          <div class="socials">
            <a
              target="_blank"
              href="https://www.facebook.com/SmoothCollieKennel/"
              class="social facebook"
            >
              <img alt="facebook" src="../assets/facebook.png" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/novuminitium_smoothcollies/"
              class="social instagram"
            >
              <img alt="instagram" src="../assets/instagram.png" />
            </a>
          </div>
        </div>
        <Slides :imageGroup="content.imageGroup" />
      </section>
    </div>
    <Dogs />
  </div>
</template>

<script>
import Dogs from "../components/Dogs.vue";
import Slides from "../components/Slides.vue";

export default {
  name: "Home",
  computed: {
    content() {
      return this.$store.getters.getContent("home");
    },
  },
  components: { Dogs, Slides },
};
</script>

<style scoped>
.news {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  width: 100%;
}

.about {
  display: flex;
  justify-content: space-evenly;
  padding: 50px 0;
  width: 100%;
}

.about .info {
  width: calc(50%);
}

.about .info .title {
  font-family: Roboto, sans-serif;
  font-size: 30px;
}

.about .info .article {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 22px;
}

.about >>> .slide-show {
  align-self: center;
  margin: auto;
  width: 40%;
}

.socials {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px 0;
}

.socials .social {
  background: var(--primary);
  height: 30px;
  padding: 10px;
  width: 30px;
}

@media screen and (max-width: 1068px) {
  .about {
    align-items: center;
    flex-direction: column;
  }

  .about .info {
    padding: 0;
    width: 100%;
  }

  .about >>> .slide-show {
    padding: 30px;
    width: calc(100% - 60px);
  }
}
</style>
