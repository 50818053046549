const errorMessages = {
  forbidden: () => "Toegang verboden",
  mail: () => "Er is een mail serverfout opgetreden",
  mysql: () => "Er is een serverfout opgetreden",
  filesystem: () => "Er is een serverfout opgetreden",
  invalid: (objectName, { field }) =>
    `${capitalizeFirstLetter(
      translateErrorFields(field) || objectName,
    )} is niet correct ingevuld`,
  not_found: (objectName, { field }) =>
    `${capitalizeFirstLetter(
      translateErrorFields(field) || objectName,
    )} is niet gevonden`,
  too_short: (objectName, { field, value }) =>
    `${capitalizeFirstLetter(
      translateErrorFields(field) || objectName,
    )} is te kort, minimaal ${value}`,
  too_long: (objectName, { field, value }) =>
    `${capitalizeFirstLetter(
      translateErrorFields(field) || objectName,
    )} is te lang, maximaal ${value}`,
  taken: (objectName, { field }) =>
    `${capitalizeFirstLetter(
      translateErrorFields(field) || objectName,
    )} is al ingebruik`,
};

const errorFields = {
  name: "naam",
  content: "inhoud",
  imageGroupId: "foto groep",
  images: "foto's",
  description: "beschrijving",
  image: "foto",
  summary: "korte beschrijving",
  type: "type",
  headerImageId: "kop afbeelding",
  message: "bericht",
  email: "email",
};

const translateErrorFields = (field) => errorFields[field];

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const getErrorText = (objectName, { error, data }) =>
  errorMessages[error](objectName, data);

/**
 *
 * @param {string} path
 * @param {RequestInit} init
 * @returns {Promise<Response>}
 */
const fetchApi = (path, init = {}, token = null) => {
  return fetch("/api/json", {
    ...init,
    headers: {
      ...(init.headers ? init.headers : {}),
      ...(token ? { token } : {}),
      "x-path": "api/v1/" + path,
    },
  });
};

const login = (username, password) => {
  return fetch("/api/json", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-path": "login",
    },
    body: JSON.stringify({
      username,
      password,
    }),
  });
};

export default {
  methods: {
    fetchApi,
    capitalizeFirstLetter,
    getErrorText,
    translateErrorFields,
    login,
  },
};
