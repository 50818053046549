import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/over",
    name: "Over",
    component: () => import("../views/Over.vue"),
  },
  {
    path: "/collie",
    name: "De Smooth Collie",
    component: () => import("../views/Collie.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/links",
    name: "Links",
    component: () => import("../views/Links.vue"),
  },
  {
    path: "/honden",
    name: "Onze Honden",
    component: () => import("../views/Honden.vue"),
  },
  {
    path: "/honden/teven",
    name: "Onze Teven",
    component: () => import("../views/Teven.vue"),
  },
  {
    path: "/honden/reuen",
    name: "Onze Reuen",
    component: () => import("../views/Reuen.vue"),
  },
  {
    path: "/honden/pups",
    name: "Onze Pups",
    component: () => import("../views/Pups.vue"),
  },
  {
    path: "/honden/:id",
    component: () => import("../views/Hond.vue"),
  },
  {
    path: "/honden/teven/:id",
    component: () => import("../views/Hond.vue"),
  },
  {
    path: "/honden/reuen/:id",
    component: () => import("../views/Hond.vue"),
  },
  {
    path: "/honden/pups/:id",
    component: () => import("../views/Hond.vue"),
  },
  {
    path: "/login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/dashboard/edit/:id",
    component: () => import("../views/dashboard/Edit.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/dashboard/dogs",
    component: () => import("../views/dashboard/Dogs.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/dashboard/dogs/add",
    component: () => import("../views/dashboard/AddDog.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/dashboard/dogs/edit/:id",
    component: () => import("../views/dashboard/EditDog.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/dashboard/images",
    component: () => import("../views/dashboard/Images.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/dashboard/images/add",
    component: () => import("../views/dashboard/AddImage.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/dashboard/images/edit/:id",
    component: () => import("../views/dashboard/EditImage.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/dashboard/images/groups",
    component: () => import("../views/dashboard/ImageGroups.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/dashboard/images/groups/add",
    component: () => import("../views/dashboard/AddImageGroup.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/dashboard/images/groups/edit/:id",
    component: () => import("../views/dashboard/EditImageGroup.vue"),
    meta: { requiresLogin: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => {
    document.getElementById("scroll").scrollIntoView();
  },
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresLogin) &&
    !store.getters.isAuthenticated
  ) {
    next("/login");
  } else {
    next();
  }
});

export default router;
