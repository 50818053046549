import { Quill } from "vue2-editor";

const fonts = [
  "Roboto",
  "Open Sans Condensed",
  "Arial",
  "Courier",
  "Garamond",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];

// Generate code-friendly font names
function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, "-");
}

const sizes = ["14px", "16px", "18px", "22px", "25px", "30px"];
export function addStyles() {
  // Add fonts to CSS style
  let styles = "";
  styles += `.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=roboto]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=roboto]::before {
    content: 'roboto';
    font-family: 'roboto', sans-serif;
    font-weight: 400;
  }
  .ql-font-roboto {
    font-family: 'roboto', sans-serif;
    font-weight: 400;
  }
  p {
   white-space: pre-wrap !important;
}
div {
  white-space: pre-wrap !important;
}`;

  fonts.forEach(function (font) {
    const fontName = getFontName(font);
    styles +=
      ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" +
      fontName +
      "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" +
      fontName +
      "]::before {" +
      "content: '" +
      font +
      "';" +
      "font-family: '" +
      font +
      "', sans-serif;" +
      "}" +
      ".ql-font-" +
      fontName +
      "{" +
      " font-family: '" +
      font +
      "', sans-serif;" +
      "}";
  });

  sizes.forEach(function (size) {
    styles += `.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="${size}"]::before {
    content: '${size}';
    font-size: ${size} !important;
}`;
  });

  const node = document.createElement("style");
  node.innerHTML = styles;
  document.body.appendChild(node);
}

export function registerQuill() {
  // Specify Quill fonts
  const fontNames = fonts.map((font) => getFontName(font));
  const font = Quill.import("formats/font");
  font.whitelist = fontNames;
  Quill.register(font, true);

  // Add custom sizes
  const size = Quill.import("attributors/style/size");
  size.whitelist = sizes;
  Quill.register(size, true);

  // Fixing tabs
  const Block = Quill.import("blots/block");
  Block.tagName = "DIV";
  Block.className = "pre";
  Quill.register(Block, true);

  return { sizes: size.whitelist, fonts: font.whitelist };
}
