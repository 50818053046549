<template>
  <section class="dogs">
    <div class="wrapper">
      <router-link to="/honden/teven" class="dog" :style="dogSize">
        <img
          v-if="!!imageTeven"
          :src="'/api/image?path=' + imageTeven.link"
          :alt="`${imageTeven.name} ${imageTeven.description}`"
        />
        <div class="info">
          <div>
            <div class="title">Onze teven</div>
            <div class="look">Bekijken</div>
          </div>
        </div>
      </router-link>
      <router-link to="/honden/reuen" class="dog" :style="dogSize">
        <img
          v-if="!!imageReuen"
          :src="'/api/image?path=' + imageReuen.link"
          :alt="`${imageReuen.name} ${imageReuen.description}`"
        />
        <div class="info">
          <div>
            <div class="title">Onze reuen</div>
            <div class="look">Bekijken</div>
          </div>
        </div>
      </router-link>
      <router-link to="/honden/pups" class="dog" :style="dogSize">
        <img
          v-if="!!imagePups"
          :src="'/api/image?path=' + imagePups.link"
          :alt="`${imagePups.name} ${imagePups.description}`"
        />
        <div class="info">
          <div>
            <div class="title">Onze pups</div>
            <div class="look">Bekijken</div>
          </div>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "Dogs",
  props: {
    size: {
      type: Number,
      default: 30,
    },
  },
  computed: {
    imageTeven() {
      const content = this.$store.getters.getContent("teven");
      return content.imageGroup.images[0] ? content.imageGroup.images[0] : null;
    },
    imageReuen() {
      const content = this.$store.getters.getContent("reuen");
      return content.imageGroup.images[0] ? content.imageGroup.images[0] : null;
    },
    imagePups() {
      const content = this.$store.getters.getContent("pups");
      return content.imageGroup.images[0] ? content.imageGroup.images[0] : null;
    },
    dogSize() {
      return {
        width:
          this.$parent.$parent.width > 768
            ? `${this.size}%`
            : `${this.$parent.$parent.width - 60}px`,
      };
    },
  },
};
</script>

<style scoped>
.dogs {
  background: var(--tertiary);
  padding: 40px 0;
  width: 100%;
}

.dogs .wrapper {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.dogs .dog {
  align-items: center;
  background: white;
  box-shadow: 0 4px 21px 5px rgb(0 0 0 / 9%);
  color: black;
  cursor: default;
  display: inline-flex;
  flex-direction: column;
  height: 400px;
  justify-content: center;
  text-decoration: none;
  width: 35%;
}

.dogs .dog img {
  display: block;
  max-height: 50%;
  object-fit: cover;
  width: 100%;
}

.dogs .dog .info {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 20px;
}

.dogs .dog .title {
  font-family: Roboto, sans-serif;
  font-size: 25px;
  margin-top: 5px;
}

.dogs .dog .look {
  background: var(--primary);
  border: 2px var(--primary) solid;
  color: white;
  cursor: pointer;
  display: block;
  font-family: Roboto, sans-serif;
  margin-top: 10px;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
}

.dogs .dog .look:hover {
  background: transparent;
  color: var(--primary);
}

@media screen and (max-width: 1068px) {
  .dogs .wrapper {
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 30px;
    width: calc(100% - 60px);
  }

  .dogs .dog {
    margin-bottom: 30px;
  }
}
</style>
