<template>
  <div id="app">
    <div id="scroll"></div>
    <header class="header">
      <div class="wrapper">
        <img alt="logo" src="./assets/header.png" />
        <a
          href="javascript:void(0);"
          :class="this.nav ? `icon open` : `icon`"
          @click="toggleNav"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </a>
        <transition name="fade-down">
          <nav class="nav" v-if="width > 869 || nav">
            <router-link to="/" :class="{ home: getCurrentPage !== 'Home' }"
              >Home</router-link
            >
            <router-link to="/over">Over ons</router-link>
            <router-link to="/collie">De Smooth Collie</router-link>
            <div class="dropdown">
              <router-link to="/honden/teven">Teven</router-link>
              <div class="dropdown-content">
                <router-link
                  v-for="dog in teven"
                  :key="dog.id"
                  :to="`/honden/teven/${dog.id}`"
                  >{{ dog.name }}</router-link
                >
              </div>
            </div>
            <div class="dropdown">
              <router-link to="/honden/reuen">Reuen</router-link>
              <div class="dropdown-content">
                <router-link
                  v-for="dog in reuen"
                  :key="dog.id"
                  :to="`/honden/reuen/${dog.id}`"
                  >{{ dog.name }}</router-link
                >
              </div>
            </div>
            <div class="dropdown">
              <router-link to="/honden/pups">Pups</router-link>
              <div class="dropdown-content">
                <router-link
                  v-for="dog in pups"
                  :key="dog.id"
                  :to="`/honden/pups/${dog.id}`"
                  >{{ dog.name }}</router-link
                >
              </div>
            </div>
            <router-link to="/contact">Contact</router-link>
            <router-link to="/links">Links</router-link>
          </nav>
        </transition>
      </div>
    </header>
    <div class="content" id="content">
      <transition name="fade">
        <keep-alive max="5">
          <router-view :key="this.$route.fullPath" />
        </keep-alive>
      </transition>
      <footer class="footer">
        <div class="wrapper">
          <div class="contact">
            Neem contact op via: linda@novum-initium.com
          </div>
          <a href="https://github.com/dj1tjoo" class="by"
            >website door <span>Thomas Brants</span></a
          >
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    this.$store.dispatch("fetchContents");
    this.$store.dispatch("fetchDogs");
  },

  data: () => ({
    width: window.innerWidth,
    nav: false,
  }),

  beforeRouteUpdate() {
    this.nav = false;
  },

  watch: {
    $route: function () {
      this.nav = false;
    },
  },

  computed: {
    getCurrentPage: function () {
      return this.$route.name;
    },
    reuen() {
      return this.$store.state.dogs?.filter((x) => x.type === 1);
    },
    teven() {
      return this.$store.state.dogs?.filter((x) => x.type === 0);
    },
    pups() {
      return this.$store.state.dogs?.filter((x) => x.type === 2);
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    if (!this.$store.state.dogs) {
      this.$store.dispatch("fetchDogs");
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    toggleNav() {
      this.nav = !this.nav;
    },
    onResize() {
      this.width = window.innerWidth;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Open+Sans+Condensed:wght@300&family=Roboto:ital,wght@0,100;0,400;0,700;1,400;1,700&display=swap");

::-webkit-scrollbar {
  background: var(--tertiary);
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
}

:root {
  --primary: #c26112; /* #d16002 */
  --tertiary: #f4f2f0;
}

img {
  max-height: 100%;
  max-width: 100%;
}

p {
  margin: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fade-down-enter-active,
.fade-down-leave-active {
  max-height: 100vh;
  transition: opacity 0.25s ease-in-out, max-height 0.5s ease-in-out;
}

.fade-down-enter,
.fade-down-leave-active {
  max-height: 0;
  opacity: 0;
}

.fade-down-leave {
  max-height: 100vh;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
}

#app {
  height: 1px;
  min-height: 100vh;
}

.wrapper {
  margin: auto;
  padding: 0 30px;
  width: calc(100% - 60px);
}

.dark {
  background: var(--tertiary);
  width: 100%;
}

.header {
  background: white;
  box-shadow: 0 4px 21px 5px rgb(0 0 0 / 9%);
  position: fixed;
  width: 100%;
  z-index: 999;
}

.header .wrapper {
  display: flex;
  height: calc(90px - 30px);
  justify-content: space-between;
  padding: 15px 0;
}

.nav {
  align-items: center;
  display: flex;
  height: 100%;
}

.nav a {
  border: 2px white solid;
  color: black;
  display: inline-block;
  font-family: Roboto, sans-serif;
  margin-right: 10px;
  padding: 10px;
  text-decoration: none;
  transition: background-color 0.2s ease-in, color 0.2s ease-in,
    border-color 0.2s ease-in;
}

.nav .dropdown {
  display: inline-block;
  position: relative;
}

.nav .dropdown-content {
  background: white;
  box-shadow: 0 4px 21px 5px rgb(0 0 0 / 9%);
  display: none;
  min-width: 160px;
  position: absolute;
  z-index: 1;
}

.nav .dropdown-content a {
  color: black;
  display: block;
  margin: 0;
  padding: 12px 16px;
  text-decoration: none;
}

.nav .dropdown:hover .dropdown-content {
  display: block;
}

.nav a.router-link-active:not(.home),
.nav .dropdown-content a.router-link-active:not(.home) {
  background: white;
  border-color: var(--primary);
  color: var(--primary);
}

.nav a.router-link-active:not(.home):hover,
.nav a:hover,
.nav .dropdown:hover > a {
  background: var(--primary);
  border-color: var(--primary);
  color: white;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 90px);
  padding-top: 90px;
}

.content::-webkit-scrollbar {
  background: var(--tertiary);
  width: 10px;
}

.content::-webkit-scrollbar-thumb {
  background: var(--primary);
}

.footer {
  background: var(--primary);
  color: rgb(255 255 255 / 80%);
  font-family: Roboto, sans-serif;
  margin-top: auto;
}

.footer .wrapper {
  align-items: center;
  display: flex;
  height: calc(90px - 30px);
  justify-content: space-between;
  padding: 15px 0;
}

.footer .contact {
  font-size: 22px;
}

.footer .by {
  color: rgb(255 255 255 / 20%);
  font-size: 22px;
  text-decoration: none;
}

.icon {
  align-self: center;
  color: black;
  cursor: pointer;
  display: none;
  height: 24px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 24px;
}

.icon span {
  background: black;
  display: block;
  height: 4px;
  left: 0;
  opacity: 1;
  position: absolute;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  width: 100%;
}

.footer .by span {
  color: rgb(255 255 255 / 50%);
  font-style: italic;
}

.icon span:nth-child(1) {
  top: 0;
}

.icon span:nth-child(2),
.icon span:nth-child(3) {
  top: 10px;
}

.icon span:nth-child(4) {
  top: 20px;
}

.icon.open span:nth-child(1) {
  left: 50%;
  top: 10px;
  width: 0%;
}

.icon.open span:nth-child(2) {
  transform: rotate(45deg);
}

.icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.icon.open span:nth-child(4) {
  left: 50%;
  top: 10px;
  width: 0%;
}

@media screen and (min-width: 1068px) {
  .wrapper {
    max-width: 1120px;
    padding: 0;
    width: calc(100% - 10px);
  }
}

@media screen and (max-width: 869px) {
  .header .wrapper {
    padding: 15px 30px;
  }

  .icon {
    display: block;
  }

  .nav {
    background: rgb(0 0 0 / 70%);
    flex-direction: column;
    height: calc(100vh - 90px - 40px);
    justify-content: start;
    left: 0;
    padding: 20px 0;
    position: fixed;
    text-align: center;
    top: 90px;
    width: 100%;
  }

  .nav a {
    border-color: transparent;
    color: white;
    margin-top: 10px;
    width: 150px;
  }

  .nav a.router-link-active:not(.home) {
    background: transparent;
    border-color: white;
    color: white;
  }

  .nav a.router-link-active:not(.home):hover,
  .nav a:hover {
    background: var(--primary);
    color: white;
  }

  .footer .wrapper {
    flex-direction: column;
    justify-content: center;
    padding: 15px 30px;
    text-align: center;
    width: calc(100% - 60px);
  }
}
</style>
